import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "0x2805FD2A1f4512A460A7e2F77E02f2a72bA08e47",
    SCAN_LINK: "https://polygonscan.com/address/0x2805fd2a1f4512a460a7e2f77e02f2a72ba08e47",
    NETWORK: {
      NAME: "Polygon",
      SYMBOL: "MATIC",
      ID: 137,
      NETWORK: "matic"
    },
    NFT_NAME: "NFTroy",
    SYMBOL: "TROY",
    MAX_SUPPLY: 1001,
    WEI_COST: 22000000000000000000,
    DISPLAY_COST: 22,
    GAS_LIMIT: 285000,
    GAS_PRICE: 35000000000,
    MARKETPLACE: "OpenSea",
    MARKETPLACE_LINK: "https://opensea.io/collection/nftroy",
    SHOW_BACKGROUND: true,
    OPENED: true,
    RPC: "https://polygon-mainnet.g.alchemy.com/v2/UbknifGjEj06GhajUqTPsGon5rWLCj6E",
    LOGO: "https://lh3.googleusercontent.com/LEhWDyNlEZcV-dWX_a3lh0Q0-JHdbJEfj8d-VLglWFWwpgqfqXI5CdmhcRsS8T8fp3Q23sMs7szpaPt33NhjRGhRa6taaB87RIRm=s130"
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let gasPrice = CONFIG.GAS_PRICE;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    console.log("Gas price: ", gasPrice);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gas: totalGasLimit,
        gasPrice: String(gasPrice),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        //setFeedback(`${CONFIG.NETWORK.NAME} unavailable » You did everything right. We even think you are the best. But the ${CONFIG.NETWORK.NAME} blockchain is burning hot right now. So please try again in a couple hours.`);
        let feedback = err.message;
        const prefix = 'You did everything right. We even think you are the best. ';
        if (feedback.indexOf('Invalid transaction params') === 0) {
          feedback = prefix + 'But your MetaMask wallet seems to have lost the connection to the Polygon network. Try to switch to another network and then back to Polygon. Then try to mint again.';
        }
        setFeedback(feedback);
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! Go visit https://opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(async () => {
    await getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <div className="app">
      <a name="mint" />
      <div className="navbar">
        <img className="jvm-logo" alt={"logo"} src={"/config/images/logo.png"} />
      </div>
      <div className="viewport1">
        <div className="minting-div">
          <div className="minting-div-text">
            <h1>NFTroy</h1>
            <p>Homer told us about the Greek outsmarting the Trojans. Great story. But this is 2022 and one horse just doesn’t do it anymore. We’re too many. And which army only has one horse anyway? That’s not diverse. 
              NFTroy brings to you the Web3 version of the Trojan Horse. Get in.
            </p>
            {blockchain.account === "" ||
              blockchain.smartContract === null ? (
                <div>
                  {CONFIG.OPENED ? (
                    <>
                      <button
                        className="minting-button"
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(connect());
                          getData();
                        }}
                      >
                        CONNECT WALLET
                      </button>
                      <div className="hint-message">
                        <p className="hint">
                          {`Connect your wallet to the ${CONFIG.NETWORK.NAME} Blockchain to get one of 1001 unique Trojan Horses.`}
                          <span>» <a href="#tutorial">Tutorial</a></span>
                        </p>
                      </div>
                      {blockchain.errorMsg !== "" ? (
                        <>
                          <s.TextDescription
                            style={{
                              color: "black",
                            }}
                          >
                            {blockchain.errorMsg}
                          </s.TextDescription>
                        </>
                      ) : null}
                    </>
                  ): (
                    <>
                      <button className="minting-button"
                      >
                        Opening soon
                      </button>
                    </>
                  )}
                </div>
              ) : (
                <>
                  {data.error ? (
                    <>
                      <button
                        className="minting-button"
                        onClick={(e) => {
                          e.preventDefault();
                          getData();
                        }}
                      >
                        Reload
                      </button>
                      <div className="hint-message">
                        <p className="hint">
                          {`You did everything right. We even think you are the best. But the ${CONFIG.NETWORK.NAME} blockchain is burning hot right now. So please try again later.`}
                        </p>
                      </div>
                    </>
                  ) : (data.loading ? (
                    <>
                      <button
                        className="minting-button"
                        disabled={true}
                      >
                        LOADING
                      </button>
                      <div className="hint-message">
                        <p className="hint">
                          {`Fetching ${CONFIG.NFT_NAME} collection details, please wait...`}
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <p className="connected supply">{data.totalSupply} / {CONFIG.MAX_SUPPLY}</p>
                      <p className="connected feedback">
                        {feedback}
                      </p>
                      <p className="connected price">
                        1 {CONFIG.SYMBOL} = <span className="connected">{data.price} {CONFIG.NETWORK.SYMBOL}</span>
                      </p>
                      <div>
                        {data.totalSupply < CONFIG.MAX_SUPPLY ? (
                          <button
                            className="minting-button"
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              claimNFTs();
                              getData();
                            }}
                          >
                            {claimingNft ? "BUSY" : "BUY"}
                          </button>
                        ) : (
                          <button
                            className="minting-button"
                            disabled={true}
                          >
                            SOLD OUT
                          </button>
                        )}
                      </div>
                    </>
                  ))}
                </>
              )}
          </div>
        </div>
        <div className="static-horse-div">
          <img className="static-horse" alt={"NFTroy"} src={"/config/images/denim_transparent.png"} />
        </div>
      </div>
      <div className="viewport2">
        <div className="gif-div">
          <img  className="gif" alt={"GIF"} src={"/config/images/example.gif"} />
        </div>
        <div className="roadmap-div">
          <div className="roadmap-text-div">
            <h2>THE ROADMAP</h2>
            <p>First, there is no roadmap. Plans change! <br/>
            Sometimes you have to run faster to catch the momentum and sometimes your life is rich when you decide where to be in the moment.</p>
            <p>That being said if you feel blue and the darkness of the deadline is coming for you, drop us a line and we will do our best and than the best after that.</p>
            <p>Sincerely,<br/>
            Jung von Matt CREATORS</p>
          </div>
        </div>
      </div>
      <div className="viewport2 tutorial">
        <div className="tutorial-wrapper">
          <h2><a name="tutorial" />HOW TO MINT IN 5 STEPS</h2>
          <ol>
            <li>Have a Polygon NFT wallet (e.g. MetaMask).</li>
            <li>Buy 23 MATIC (not 22 because you need to pay Gas fee). You can buy the MATIC either directly with your wallet (e.g. MetaMask) or with a Crypto App (Crypto.com or Coinbase)</li>
            <li>If you didn't buy within the wallet, transfer the 23 MATIC to your NFT Wallet (e.g. MetaMask). With some Crypto apps, e.g. Crypto.com, you can transfer the MATIC directly on the POLYGON chain without paying afterwards for bridging from the Ethereum chain.</li>
            <li>Connect your wallet with the website and » <a href="#mint">buy</a>. (sometimes the Polygon chain is busy, than try again later)</li>
            <li>You can & will live happily without owning this NFTroy! If you need this tutorial, don't do it!</li>
          </ol>
        </div>
      </div>
    </div>
  );
}

export default App;
